export default {
  getGroupedActions(store) {
    const groupMap = new Map();

    store.list.forEach(action => {
      const key = action.bgGroupId || action.bgActionId;

      if (!groupMap.has(key)) {
        groupMap.set(key, []);
      }
      groupMap.get(key).push(action);
    });

    return Array.from(groupMap.values()).reverse();
  },

  hasProcessingActions: (state) => () => {
    return state.list.filter(item => item.status == 'in_progress').length > 0
  }
};