import AppActions from './actions-types'
import AppMutations from './mutations-types'

import api from '@/services/api'
import notification from '@/services/notification'

export default {
  [AppActions.OPEN_APP_ERROR_MESSAGE]({ commit }, message) {
    commit(AppMutations.SET_APP_MESSAGE_TEXT, message)
    commit(AppMutations.SET_APP_MESSAGE_TYPE, 'error')
    commit(AppMutations.SET_APP_MESSAGE_OPEN, true)
  },

  [AppActions.OPEN_APP_SUCCESS_MESSAGE]({ commit }, message) {
    commit(AppMutations.SET_APP_MESSAGE_TEXT, message)
    commit(AppMutations.SET_APP_MESSAGE_TYPE, 'success')
    commit(AppMutations.SET_APP_MESSAGE_OPEN, true)
  },

  [AppActions.OPEN_APP_INFO_MESSAGE]({ commit }, message) {
    commit(AppMutations.SET_APP_MESSAGE_TEXT, message)
    commit(AppMutations.SET_APP_MESSAGE_TYPE, 'info')
    commit(AppMutations.SET_APP_MESSAGE_OPEN, true)
  },

  [AppActions.GET_AND_SET_USER_APPLICATIONS]({ commit }) {
    return new Promise((resolve, reject) => {
      api.core.application_instance
        .getUserAppInstances()
        .then((response) => {
          commit(AppMutations.SET_USER_APPLICATIONS, response.data.items)
          commit(AppMutations.SET_USER_MENU_ITEMS, response.data.items)

          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [AppActions.SET_CONTENT_PERSONAL_PREFERENCE]({ commit }, params) {
    commit(AppMutations.UPDATE_CONTENT_PERSONAL_PREFERENCE, params)
  },

  [AppActions.CONNECT_WEBSOCKET]({ commit }) {
    const socket = notification.connect()
    commit(AppMutations.SET_SOCKET, socket)
  },

  [AppActions.DISCONNECT_WEBSOCKET]({ commit, state }) {
    state.socket.disconnect()
    commit(AppMutations.SET_SOCKET, null)
  },

  [AppActions.SUBSCRIBE_TO_ROOM]({ state }, room) {
    state.socket.emit('subscribe', room)
  }
}
