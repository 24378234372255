import axios from 'axios'
import store from '@/store'
import router from '@/router'
import AppActions from '@/store/app/actions-types'
import AuthActions from '@/store/core/auth/actions-types'
import { isTokenNearExpiry } from '@/store/core/auth/token-utils'

let config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

const AxiosFileService = axios.create(config)

AxiosFileService.interceptors.request.use(
  async function(config) {
    if (store.state.core.auth.token && isTokenNearExpiry(store.state.core.auth.token)) {
      try {
        await store.dispatch('core/auth/' + AuthActions.RENEW_TOKEN)
      } catch (error) {
        store.dispatch('app/' + AppActions.OPEN_APP_ERROR_MESSAGE, error.message || "Token error.")
        .then(() => {
          store
          .dispatch('core/auth/' + AuthActions.DO_LOGOUT)
          .then(() => {
            router.push('/login')
          })
        })
      }
    }

    if (store.state.core.auth.token) {
      config.headers.common['token'] = store.state.core.auth.token
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

AxiosFileService.interceptors.response.use(
  function(response) {
    return response;
  },

  function(error) {
    if (error.response) {
      if (error.response.status == 403) {
        router.push('/')
      }

      if (error.response.status == 401 && store.state.core.auth.authenticated) {
        store
          .dispatch('core/auth/' + AuthActions.DO_LOGOUT)
          .then(() => {
            router.push('/login')
          })
      }

      if (error.response.data.message) {
        store.dispatch('app/' + AppActions.OPEN_APP_ERROR_MESSAGE, error.response.data.message)
      }
    }

    return Promise.reject(error)
  }
)

export default AxiosFileService