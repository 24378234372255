import io from 'socket.io-client';
import store from '@/store'

export default {
  connect: () => {
    const socket = io(process.env.VUE_APP_NOTIFICATION_SERVER, {
      query: {
        user_id: store.state.core.auth.userData.users_id
      }
    })

    return socket
  }
}
