import NodeTypes from './node-types'

export default {
  nodeCardActions: [
    {
      key: "edit_gdocs",
      icon: 'mdi-google',
      title: 'edit',
      callMethod: "editGDocAction",
      group: 'editActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "convert_to_gdocs",
      icon: 'mdi-file-replace-outline',
      title: 'convert_to_gdocs',
      callMethod: "convertToGDocsAction",
      group: 'editActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "edit_self_service_template",
      icon: 'mdi-image-multiple-outline',
      title: 'edit',
      callMethod: "editJstAction",
      group: 'editActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "edit_synthetic_media",
      icon: 'mdi-creation-outline',
      title: 'edit',
      callMethod: "editSyntheticMediaAction",
      group: 'editActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "preview",
      icon: 'mdi-eye-outline',
      title: 'preview',
      callMethod: "previewAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "information",
      icon: 'mdi-information-outline',
      title: 'information',
      callMethod: "infoAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "download",
      icon: 'mdi-download',
      title: 'download',
      callMethod: "downloadAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "rename",
      icon: 'mdi-form-textbox',
      title: 'rename',
      callMethod: "renameAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "copy",
      icon: 'mdi-content-copy',
      title: 'copy',
      callMethod: "copyAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "move",
      icon: 'mdi-folder-move-outline',
      title: 'move',
      callMethod: "moveAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "share_network_drive",
      icon: 'mdi-folder-network',
      title: 'share_network_drive',
      callMethod: "shareNetworkDriveAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
      ],
    },
    {
      key: "custom_action",
      icon: 'mdi-motion-play-outline',
      title: 'custom_action',
      callMethod: "customAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "security_classification",
      icon: 'mdi-security',
      title: 'security_classification',
      callMethod: "securityClassificationAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "share_download_link",
      icon: 'mdi-share-variant-outline',
      title: 'share_download_link',
      callMethod: "shareModalAction",
      group: 'generalActions',
      allowInLocked: false,
      types: [
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "grant",
      icon: 'mdi-account-plus',
      group: 'advancedAction',
      title: 'grant_access',
      callMethod: "grantAccessAction",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "node_token",
      icon: 'mdi-lock-open',
      group: 'advancedAction',
      title: 'nodetoken_action_title',
      callMethod: "nodetokenAccessAction",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "attach_security_groups",
      icon: 'mdi-shield-lock',
      group: 'advancedAction',
      title: 'attach_security_groups',
      callMethod: "attachSecurityGroupsAction",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "attach_metadata_fieldsets",
      icon: 'mdi-id-card',
      group: 'advancedAction',
      title: 'attach_metadata_fieldsets',
      callMethod: "attachMetadataFieldsets",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "attach_actionset",
      icon: 'mdi-play-box-multiple',
      group: 'advancedAction',
      title: 'attach_actionsets',
      callMethod: "attachActionsetsAction",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "attach_enabler",
      icon: 'mdi-leaf',
      group: 'advancedAction',
      title: 'attach_enablers',
      callMethod: "attachEnablerAction",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "attach_automations",
      icon: 'mdi-play-speed',
      group: 'advancedAction',
      title: 'attach_automations',
      callMethod: "attachAutomations",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
    {
      key: "sync",
      icon: 'mdi-sync',
      group: 'advancedAction',
      title: 'sync',
      callMethod: "syncAction",
      allowInLocked: false,
      types: [
        NodeTypes.NODE_REPOSITORY,
        NodeTypes.NODE_FOLDER
      ],
    },
    {
      key: "remove",
      icon: 'mdi-delete-outline',
      group: 'lastAction',
      title: 'remove',
      callMethod: "removeAction",
      allowInLocked: true,
      types: [
        NodeTypes.NODE_FOLDER,
        NodeTypes.NODE_FILE,
      ],
    },
  ],
}
