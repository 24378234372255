import AxiosContent from '@/services/api/content/axios_content'

export default {
  uploadFile: ({parentId, file}, source, onUploadProgress) => {
    let formData = new FormData()

    formData.append("folder_parent_id", parentId)
    formData.append("file", file)

    return AxiosContent.post("/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      cancelToken: source.token,
      onUploadProgress
    })
  },

  prepareFileToContent: ({parentId, file}) => {
    let formData = new FormData()
    formData.append("folder_parent_id", parentId)
    formData.append("filename", file.name)
    return AxiosContent.post("/file/prepare", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
}
