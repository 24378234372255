import BackgroundActions from './actions-types'
import BackgroundMutations from './mutations-types'
import EntryMutations from '@/store/content/entry/mutations-types'
import axios from "axios";
import { BgActionEventBus } from '@/bgActionEventBus';

export default {
  [BackgroundActions.CREATE_BACKGROUND_ACTION]({ commit }, message) {
    const source = axios.CancelToken.source();
    const bgActionId = message.bgActionId ?? Math.random().toString(36).substr(2, 9)

    const bgAction = {
      bgActionId: bgActionId,
      bgGroupId: message.bgGroupId,
      title: message.title,
      type: message.type,
      icon: message.icon,
      text: message.text,
      createdAt: new Date(),
      progress: message.progress ?? 0,
      status: message.status ?? 'in_progress',
      cancelSource: source
    }

    commit(BackgroundMutations.REGISTER_BACKGROUND_ACTION, bgAction)

    return bgAction
  },

  [BackgroundActions.CREATE_OR_UPDATE_BACKGROUND_ACTION]({ commit, dispatch, state }, message) {
    let item = state.list.find(item => item.bgActionId == message.bgActionId)
    BgActionEventBus.$emit('message', message)

    if (item) {
      commit(BackgroundMutations.UPDATE_BACKGROUND_ACTION, message)

      if (message.status == 'done' && message.type == 'move') {
        commit(`content/entry/${[EntryMutations.REMOVE_ENTRY]}`, message.node_id, { root:true })
      }

      return
    }

    dispatch(BackgroundActions.CREATE_BACKGROUND_ACTION, message)
  },

  [BackgroundActions.CANCEL_BACKGROUND_ACTION]({ commit }, bgAction) {
    bgAction.cancelSource.cancel()

    commit(BackgroundMutations.REMOVE_BACKGROUND_ITEM, bgAction.bgActionId)
  },

  [BackgroundActions.CLEAR_BACKGROUND_ACTION_LIST]({ commit }) {
    commit(BackgroundMutations.REMOVE_ALL_COMPLETED_BACKGROUND_ACTIONS)
  },

  [BackgroundActions.CLEAR_NEW_ACTIONS_NUMBER]({ commit }) {
    commit(BackgroundMutations.SET_NEW_ACTIONS_NUMBER, 0)
  },

  [BackgroundActions.HANDLE_PIPELINE_MESSAGES]({ commit, state }, message) {
    if (message.node_id && message.type == 'rendition_service') {
      const item = state.list.find(item => item.nodeId == message.node_id)

      if (item && message.status == 'in_progress') {
        commit(BackgroundMutations.UPDATE_BACKGROUND_ACTION, {bgActionId: item.bgActionId, status: 'generating_preview'})
      } else {
        commit(BackgroundMutations.UPDATE_BACKGROUND_ACTION, {bgActionId: item.bgActionId, status: message.status})
      }

      BgActionEventBus.$emit('pipeline', message)
    }
  },

}