<template>
  <v-app :style="`font-family: ${appFont} !important`">
    <v-navigation-drawer
      class="custom-nav-drawer"
      color="appBarBgColor"
      :mini-variant.sync="drawerMini"
      v-if="authenticated"
      v-model="drawer"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
      floating
      left
      app
      clipped
    >
        <v-list nav class="pt-6 pt-md-0 mt-6 mt-md-0">
          <template 
            v-for="item in validMenuItems"
          >

            <v-list-group
              v-if="item.children.length > 1"
              :key="item.title"
              v-model="item.active"
              :group="item.path"
              active-class="active-nav-group"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon v-if="item.active" v-text="item.icon_outline"></v-icon>
                  <v-icon v-else v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.children"
                :key="child.application_instance_id"
                :to="getAppInstanceHref(item, child)"
                :class="isActiveSubMenuItem(item, child) ? 'v-list-item--active active-nav-item' : ''"
                :exact="item.key == 'home'"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.application_instance_title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else-if="item.key != 'home'"
              :key="item.title"
              v-model="item.active"
              :to="getAppInstanceHref(item, item.children[0])"
              :class="isActiveSubMenuItem(item, item.children[0]) ? 'v-list-item--active active-nav-item' : ''"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </template>
        </v-list>

        <v-spacer></v-spacer>

        <template v-slot:append>
          <v-list nav>
            <v-list-item
              v-if="privilegedUser() == true"
              to="/settings"
              active-class="active-nav-item"
              ref="settings"
            >
              <v-list-item-icon>
                <v-icon>{{settingsIcon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t('settings')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="mb-2 hidden-sm-and-down">

            <v-divider light class="divider mb-2"></v-divider>

            <v-btn
              class="mr-2 mb-2 d-flex float-right"
              @click="drawerMini = !drawerMini"
              icon
            >
              <v-icon
                color="appBarTxtColor"
                v-if="!drawerMini"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon
                color="appBarTxtColor"
                v-if="drawerMini"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>

          </div>
        </template>

    </v-navigation-drawer>

    <v-app-bar
      v-if="authenticated"
      app
      color="appBarBgColor"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        class="title-img"
      >
        <router-link :to="{name: 'Home'}">
          <v-img
            :src=appLogo
            height="35px"
            width="200px"
            contain
          ></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div
        class="d-flex"
      >
        <div class="pa-2">
          <BackgroundActionList />
        </div>

        <div class="pa-2">
          <UserAvatarOptions />
        </div>
      </div>

    </v-app-bar>

    <v-snackbar
      :timeout="message.timeout"
      :value="message.open"
      bottom
      style="margin-bottom:20px"
      :color="message.type"
      v-on:input="messageChanged"
    >
      <v-icon v-if="message.type == 'error'">mdi-alert</v-icon>
      <v-icon v-if="message.type == 'info'">mdi-information</v-icon>
      <v-icon v-if="message.type == 'success'">mdi-check-circle</v-icon>

      {{ message.text }}

      <v-spacer></v-spacer>

      <template v-slot:action>
        <v-btn
          color="appBarTxtColor"
          text
          @click="closeAlert()"
        >
          {{ $t('close') }}
        </v-btn>
      </template>

    </v-snackbar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
  .main {
    background-color: var(--v-appBackground-base);
  }

  .title-img {
    padding-left: 10px !important;
  }
</style>

<style lang="scss">
  @import '@/styles/container-width';
  @import '@/styles/font';
</style>

<style lang="scss" scoped>

  .divider {
    width: 100%;
    border-color: var(--v-appBarTxtColor-darken3) !important;
  }

  ::v-deep .custom-nav-drawer .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  ::v-deep .custom-nav-drawer .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) .v-icon,
  .v-btn__content > i {
    color: var(--v-appBarTxtColor-darken3) !important;
    font-weight: 400;
  }

  ::v-deep .custom-nav-drawer .v-list-item {
    color: var(--v-appBarTxtColor-base);
    font-weight: 500;
  }

</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import ThemeActions from "@/store/core/theme/actions-types"
import AppActions from "@/store/app/actions-types"
import AppMutations from "@/store/app/mutations-types"
import BackgroundActions from '@/store/background_actions/actions-types.js'

import BackgroundActionList from '@/components/app/BackgroundActionList.vue'
import UserAvatarOptions from '@/components/app/UserAvatarOptions.vue'
import UserRoles from '@/store/core/user/user-roles.js'

export default {
  name: 'App',

  components: {
    BackgroundActionList,
    UserAvatarOptions,
  },

  created() {
    window.addEventListener('beforeunload', (event) => {
      if (this.hasProcessingActions()) {
        event.preventDefault()
        event.returnValue = ''
      }
    })
  },

  computed: {
    ...mapGetters('background_actions', [
      'hasProcessingActions'
    ]),

    ...mapState({
      authenticated: state => state.core.auth.authenticated,
      userData: state => state.core.auth.userData,
      userRoles: state => state.core.auth.userRoles,
      menuItems: state => state.app.menuItems,
      message: state => state.app.message,
      socket: state => state.app.socket
    }),

    validMenuItems() {
      return this.menuItems.filter(mi => mi.children.length > 0)
    },

    appFont() {
      if(this.$vuetify.theme.themes.light.font)
        return this.$vuetify.theme.themes.light.font
      return 'Roboto, sans-serif'

    },

    appLogo() {
      if(this.$vuetify.theme.themes.light.logo)
        return this.$vuetify.theme.themes.light.logo
      return require('@/assets/logo_jamworks.svg')
    }
  },

  data: () => ({
    drawer: true,
    drawerMini: true,
    settingsIcon: "mdi-cog",
    activeItem: 'MasterTenant'
  }),

  watch: {
    'authenticated'() {
      if (this.authenticated) {
        this[AppActions.CONNECT_WEBSOCKET]()

        this.socket.on('message', (message) => {
          message.bgActionId = message.bg_action_id
          message.bgGroupId = message.bg_group_id

          this[BackgroundActions.CREATE_OR_UPDATE_BACKGROUND_ACTION](message)
        });

        this.socket.on('pipeline', (message) => {
          message.bgActionId = message.bg_action_id
          message.bgGroupId = message.bg_group_id

          this[BackgroundActions.HANDLE_PIPELINE_MESSAGES](message)
        });

        this.setUserTheme()
      }

      if (!this.authenticated && this.socket) {
        this[AppActions.DISCONNECT_WEBSOCKET]()
      }
    }
  },

  updated() {
    if(this.$vuetify.breakpoint.smAndDown)
      this.drawerMini = false

    if(this.$refs.settings && this.$refs.settings.$el.classList.contains("v-list-item--active")) {
      this.settingsIcon = "mdi-cog-outline";
    } else {
      this.settingsIcon = "mdi-cog";
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.CONNECT_WEBSOCKET,
      AppActions.DISCONNECT_WEBSOCKET
    ]),

    ...mapActions('background_actions', [
      BackgroundActions.CREATE_OR_UPDATE_BACKGROUND_ACTION,
      BackgroundActions.HANDLE_PIPELINE_MESSAGES
    ]),

    ...mapActions('core/theme', [
      ThemeActions.GET_USER_SELECTED_THEME
    ]),

    ...mapMutations('app', [
      AppMutations.SET_APP_MESSAGE_OPEN
    ]),

    closeAlert() {
      this[AppMutations.SET_APP_MESSAGE_OPEN](false)
    },

    privilegedUser() {
      for (let i = 0; i < this.userRoles.length; i++) {
        if (this.userRoles[i] != UserRoles.USER_REGULAR) {
          return true;
        }
      }
      return false
    },

    messageChanged() {
      this[AppMutations.SET_APP_MESSAGE_OPEN](false)
    },

    getAppInstanceHref(menuItem, child) {
      return menuItem.href.replace(/:appId/, child.application_instance_id)
    },

    isActiveSubMenuItem(menuItem, child) {
      let subItemPath = ""

      if (menuItem.key == 'home') {
        subItemPath = this.getAppInstanceHref(menuItem, child)

        return this.$route.fullPath.startsWith(subItemPath)
      }

      subItemPath = `${menuItem.path}/${child.application_instance_id}`

      return this.$route.path.startsWith(subItemPath)
    },

    setUserTheme() {
      this[ThemeActions.GET_USER_SELECTED_THEME](this.userData.users_id)
        .then((theme) => {
          if (! theme) {
            return
          }

          this.$vuetify.theme.themes.light = JSON.parse(theme.theme_config)
        })
    },
  }
};
</script>
