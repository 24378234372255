<template>
  <v-list-group
    :append-icon="showTextMessage ? 'mdi-menu-down' : null"
    :disabled="!showTextMessage"
    :ripple="false"
  >
    <template v-slot:activator>
      <v-list-item dense>
        <v-list-item-content class="flex-nowrap">
          <v-list-item-icon class="align-self-center ma-0 flex-shrink-1">
            <v-icon>{{ actionIcon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content :class="titleClass" class="mr-2 title-box">
            <v-list-item-title class="text-wrap">
              {{ data.title || '' }} <span class="font-weight-bold">({{ status }})</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="flex-shrink-1 justify-center ma-0">
            <v-btn
              v-if="data.status == 'in_progress' && data.cancelSource"
              icon
              @click.stop="cancel"
            >
              <v-icon size="18" color="defaultIconColor">mdi-close-circle</v-icon>
            </v-btn>

            <v-icon
              v-else
              size="18"
              :color="statusIcon.color"
            >{{ statusIcon.icon }}</v-icon>
          </v-list-item-action>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list-item>
      <v-list-item-subtitle class="text-wrap ma-4 mt-0 ml-7 pl-6">
        {{ data.text }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list-group>
</template>

<style scoped>
  .word-break {
    word-break: break-all;
    overflow-wrap: break-word;
    text-wrap: wrap;
    hyphens: auto;
  }

  .title-box {
    max-width: 70%;
  }

  .v-list-item {
    padding: 0;
  }

  *:hover {
    background-color: transparent !important;
  }

  >>>.v-list-group__header__append-icon {
    margin: 0 !important;
    min-width: 24px !important;
    padding-right: 6px !important;
  }
  
  >>>.v-list-group__header{
    padding-right: 0 !important;
  }

  >>>.v-list-item:hover::before {
    opacity: 0 !important;
  }
</style>

<script>

import { mapActions } from 'vuex'

import BackgroundActions from '@/store/background_actions/actions-types.js'
import HumanReadable from "@/utils/human_readable";

export default {
  name: 'BackgroundActionItem',

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    status() {
      if (this.data.status != 'in_progress') {
        return this.$t(this.data.status)
      }

      return `${this.data.progress}%`
    },

    subtitle() {
      return HumanReadable.epochTimestampToDate(this.data.createdAt, true);
    },

    actionIcon() {

      if(this.data.icon) {
        return this.data.icon
      }

      switch (this.data.type) {
        case 'upload': return 'mdi-file-upload-outline'
        case 'move': return 'mdi-folder-move-outline'
        case 'copy': return 'mdi-content-copy'
        case 'delete': return 'mdi-delete-outline'
        case 'customAction': return 'mdi-motion-play-outline'
        case 'export': return 'mdi-image-multiple-outline'
        case 'synthetic_image_txt2img': return 'mdi-creation-outline'
        case 'synthetic_image_upscale': return 'mdi-resize'
        default: return 'mdi-reload';
      }
    },

    titleClass() {
      const words = this.data.title?.split(/\s+/);
      if(words && words.length > 0) {
        const hasLongWord = words.some(word => word.length > 24);
        return hasLongWord ? 'word-break' : 'text-wrap';
      }
      return 'text-wrap';
    },

    statusIcon() {
      switch(this.data.status) {
        case 'in_progress': return({icon:'mdi-clock-time-four', color:'defaultIconColor'})
        case 'success': return({icon:'mdi-check-circle', color:'success'})
        case 'done': return({icon:'mdi-check-circle', color:'success'})
        case 'error': return({icon:'mdi-alert-circle', color:'error'})
        case 'partial_error': return({icon:'mdi-alert-octagon', color:'warning'})
        default: return({icon:'mdi-information', color:'defaultIconColor'})
      }
    },

    showTextMessage() {
      return this.data.status == 'error' && this.data.text
    }
  },

  methods: {
    ...mapActions('background_actions', [
      BackgroundActions.CANCEL_BACKGROUND_ACTION,
    ]),

    cancel() {
      this[BackgroundActions.CANCEL_BACKGROUND_ACTION](this.data)
    }
  }
}

</script>