import UserRoles from '@/store/core/user/user-roles'

export default {
  userApplications: [],
  selectedContentApp: null,
  selectedCdpApp: null,
  socket: null,
  applications: [
    {
      id: 1,
      key: 'content',
      title: 'Contents'
    },
    {
      id: 2,
      key: 'project',
      title: 'Projects'
    },
    {
      id: 3,
      key: 'node',
      title: 'Node'
    },
    {
      id: 4,
      key: 'pipeline',
      title: 'Pipeline'
    },
    {
      id: 5,
      key: 'core',
      title: 'Core'
    },
    {
      id: 6,
      key: 'cdp',
      title: 'CDP'
    },
    {
      id: 7,
      key: 'synthetic_image',
      title: 'AI Generated Media'
    },
    {
      id: 8,
      key: 'home',
      title: 'Home'
    }
  ],
  message: {
    open: false,
    type: null,
    text: null,
    timeout: 10000
  },
  menuItems: [
    {
      title: 'home',
      key: 'home',
      icon: 'mdi-home',
      icon_outline: 'mdi-home-outline',
      path: '^(\\/)$',
      href: '/?appId=:appId',
      reference_id: 8,
      children: []
    },
    {
      title: 'cdp',
      key: 'cdp',
      icon: 'mdi-cube',
      icon_outline: 'mdi-cube-outline',
      path: '/cdp',
      href: '/cdp/:appId/profile_view_export',
      reference_id: 6,
      children: []
    },
    {
      title: 'contents',
      key: 'content',
      icon: 'mdi-view-dashboard',
      icon_outline: 'mdi-view-dashboard-outline',
      path: '/content',
      href: '/content/:appId/navigation/home',
      reference_id: 1,
      children: []
    },
  ],
  coreSettingsItems: [
    {
      title: 'master_users',
      icon: 'mdi-account-box',
      url: '/settings/core/master_user',
      access_role: [
        UserRoles.USER_MASTER
      ]
    },
    {
      title: 'apps_instance',
      icon: 'mdi-content-duplicate',
      url: '/settings/core/application_instance',
      access_role: [
        UserRoles.USER_MASTER
      ]
    },
    {
      title: 'tenants',
      icon: 'mdi-home-account',
      url: '/settings/core/tenant',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    },
    {
      title: 'companies',
      icon: 'mdi-office-building',
      url: '/settings/core/company',
      access_role: [
        UserRoles.USER_ADMIN
      ]
    },
    {
      title: 'groups',
      icon: 'mdi-account-multiple',
      url: '/settings/core/group',
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER
      ]
    },
    {
      title: 'users',
      icon: 'mdi-account',
      url: '/settings/core/user',
      access_role: [
        UserRoles.USER_ADMIN
      ]
    },
    {
      title: 'themes',
      icon: 'mdi-palette-outline',
      url: '/settings/core/theme',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
  ],
  operationSettingsItems: [
    {
      title: 'fields',
      icon: 'mdi-id-card',
      url: '/settings/operation/field',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'actions',
      icon: 'mdi-play-box',
      url: '/settings/operation/action',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'actionsets',
      icon: 'mdi-play-box-multiple',
      url: '/settings/operation/actionset',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'enablers',
      icon: 'mdi-leaf',
      url: '/settings/operation/enabler',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'security',
      icon: 'mdi-security',
      url: '/settings/operation/security/group',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'automations',
      icon: 'mdi-play-speed',
      url: '/settings/operation/automation',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'filters',
      icon: 'mdi-filter',
      url: '/settings/operation/filter',
      access_role: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    }
  ],
  cdpSettingsItems: [
    {
      title: 'customer_properties',
      icon: 'mdi-card-account-details',
      routeName: 'CustomerProperty',
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    },
    {
      title: 'unomi_config',
      icon: 'mdi-circle-double',
      routeName: 'UnomiConfig',
      access_role: [
        UserRoles.USER_ADMIN
      ]
    },
    {
      title: 'unomi_console',
      icon: 'mdi-console',
      routeName: 'UnomiConsole',
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'attribute_mapping',
      icon: 'mdi-file-tree',
      routeName: 'AttributeMapping',
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    },
    {
      title: 'profile_view',
      icon: 'mdi-clipboard-account',
      routeName: 'ProfileView',
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    },
  ],
  contentSettingsItems: [
    {
      title: 'file_servers',
      icon: 'mdi-server',
      routeName: "FileServer",
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    },
    {
      title: 'repositories',
      icon: 'mdi-database',
      routeName: "Repository",
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    },
  ],
  contentPersonalPreferences: {
    navigationViewMode: localStorage.getItem('navigationViewMode') || 'grid',
  },
}