export default {
  OPEN_APP_ERROR_MESSAGE: 'openAppErrorMessage',
  OPEN_APP_SUCCESS_MESSAGE: 'openAppSuccessMessage',
  OPEN_APP_INFO_MESSAGE: 'openAppInfoMessage',
  GET_AND_SET_USER_APPLICATIONS: 'getAndSetUserApplications',
  SET_CONTENT_PERSONAL_PREFERENCE: 'setContentPersonalPreference',
  CONNECT_WEBSOCKET: 'connectWebsocket',
  DISCONNECT_WEBSOCKET: 'disconnectWebsocket',
  SUBSCRIBE_TO_ROOM: 'subscribeToRoom'
}
