import grant from './grant'
import nodetoken from './nodetoken'

export default {
  namespaced: true,
  modules: {
    grant,
    nodetoken,
  }
}
