import api from '@/services/api'
import NodeTokenActions from './actions-types'

export default {
  [NodeTokenActions.GET_NODETOKEN_BY_NODE]({}, nodeTokenId) {
    return new Promise((resolve, reject) => {
      api.node.nodetoken
        .getNodeTokenByNode(nodeTokenId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  [NodeTokenActions.GET_NODETOKEN_BY_NODE_AND_PARENTS]({}, nodeTokenId) {
    return new Promise((resolve, reject) => {
      api.node.nodetoken
        .getNodeTokenByNodeAndParents(nodeTokenId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  [NodeTokenActions.ADD_NODETOKEN]({}, data) {
    return new Promise((resolve, reject) => {
      api.node.nodetoken
        .createNodeToken(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [NodeTokenActions.DELETE_NODETOKEN]({}, nodeTokenId) {
    return new Promise((resolve, reject) => {
      api.node.nodetoken
        .deleteNodeToken(nodeTokenId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
}