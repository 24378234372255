import core from './api/core'
import cdp from './api/cdp'
import content from './api/content'
import fileservice from './api/fileservice'
import node from './api/node'

export default {
  core,
  cdp,
  content,
  fileservice,
  node
}
