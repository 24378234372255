import AxiosContent from '@/services/api/content/axios_content'

export default {
  renameEntry: (nodeId, data) => {
    return AxiosContent.put(`entry/rename/${nodeId}`, data)
  },

  removeEntry: (nodeId, data = {}) => {
    return AxiosContent.delete(`entry/${nodeId}`, {data: data})
  },

  syncEntry: (nodeId) => {
    return AxiosContent.get(`entry/sync/${nodeId}`)
  },

  moveEntry: (nodeId, data, source, onUploadProgress) => {
    return AxiosContent.post(`entry/move/${nodeId}`, data, {
      cancelToken: source.token,
      onUploadProgress
    })
  },

  moveEntries: (data) => {
    return AxiosContent.post(`entry/move`, data)
  },

  copyEntry: (nodeId, destinationId, data) => {
    return AxiosContent.put(`entry/copy/${nodeId}/to/${destinationId}`, data)
  },

  customAction: (nodeId, pipeline_id, data) => {
    return AxiosContent.put(`entry/action/${nodeId}/pipeline/${pipeline_id}`, data)
  },

  checkPipeline: (nodeId, pipeline_id) => {
    return AxiosContent.get(`entry/check/${nodeId}/pipeline/${pipeline_id}`)
  },
}
