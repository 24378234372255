import AxiosNode from '@/services/api/node/axios_node'

export default {
  getNodeTokenByNode: (nodeId) => {
    return AxiosNode.get(`/nodetoken/node/${nodeId}`)
  },
  getNodeTokenByNodeAndParents: (nodeId) => {
    return AxiosNode.get(`/nodetoken/node/${nodeId}`)
  },
  createNodeToken: (data) => {
    return AxiosNode.post(`/nodetoken`, data)
  },
  deleteNodeToken: (nodeTokenId) => {
    return AxiosNode.delete(`/nodetoken/${nodeTokenId}`)
  },
}