import BackgroundMutations from './mutations-types'
import Vue from 'vue'

export default {
  [BackgroundMutations.REGISTER_BACKGROUND_ACTION](state, bgAction) {
    state.list.push(bgAction)
  },

  [BackgroundMutations.UPDATE_BACKGROUND_ACTION_PROGRESS](state, { bgActionId, progress, nodeId }) {
    let bgAction = state.list.find(i => i.bgActionId == bgActionId)

    const newStatus = (progress >= 100) ? 'success' : bgAction.status

    Vue.set(bgAction, 'progress', progress)
    Vue.set(bgAction, 'status', newStatus)
    Vue.set(bgAction, 'node_id', nodeId)
  },

  [BackgroundMutations.UPDATE_BACKGROUND_ACTION](state, message) {
    const item = state.list.find(item => item.bgActionId === message.bgActionId)

    if (!item) {
      return
    }

    Vue.set(item, 'status', message.status ?? item.status)
    Vue.set(item, 'progress', message.progress ?? item.progress)
    Vue.set(item, 'title', message.title ?? item.title)
    Vue.set(item, 'text', message.text ?? item.text)
    Vue.set(item, 'icon', message.icon ?? item.icon)
    Vue.set(item, 'type', message.type ?? item.type)
    Vue.set(item, 'nodeId', message.nodeId ?? item.nodeId)
  },

  [BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS](state, { bgActionId, status }) {
    state.list.find(i => i.bgActionId == bgActionId).status = status
  },

  [BackgroundMutations.UPDATE_BACKGROUND_ACTION_TITLE](state, { bgActionId, title }) {
    state.list.find(i => i.bgActionId == bgActionId).title = title
  },

  [BackgroundMutations.REMOVE_BACKGROUND_ITEM](state, bgActionId) {
    state.list = [...state.list.filter(i => i.bgActionId != bgActionId)]
  },

  [BackgroundMutations.REMOVE_ALL_COMPLETED_BACKGROUND_ACTIONS](state) {
    state.list = [...state.list.filter(i => i.status == 'in_progress')]
  },

  [BackgroundMutations.ADD_NEW_ACTIONS_NUMBER](state, ammount = 1) {
    state.newActionsNumber += ammount
  },

  [BackgroundMutations.SET_NEW_ACTIONS_NUMBER](state, ammount = 0) {
    state.newActionsNumber = ammount
  },
}
