export default {
  SET_APP_MESSAGE_TYPE: 'setAppMessageType',
  SET_APP_MESSAGE_TEXT: 'setAppMessageText',
  SET_APP_MESSAGE_OPEN: 'setAppMessageOpen',
  SET_USER_APPLICATIONS: 'setUserApplications',
  SET_USER_MENU_ITEMS: 'setUserMenuItems',
  SET_SELECTED_CONTENT_APP: 'setSelectedContentApp',
  SET_SELECTED_CDP_APP: 'setSelectedCdpApp',
  UPDATE_CONTENT_PERSONAL_PREFERENCE: 'updateContentPersonalPreference',
  SET_SOCKET: 'setSocket'
}