import Vue from 'vue'
import SyntheticMutations from './mutations-types'

export default {
  [SyntheticMutations.SET_CURRENT_GENERATION_MESSAGE](state, message) {
    state.currentGenerationMessage = message
  },

  [SyntheticMutations.ADD_QUEUE_ITEM](state, item) {
    state.queues.push(item)
  },

  [SyntheticMutations.UPDATE_QUEUE_ITEM](state, item) {
    const index = state.queues.findIndex(queue => queue.offset == item.offset && queue.topic == item.topic)

    const updatedObj = {...state.queues[index], ...item}

    Vue.set(state.queues, index, updatedObj)
  },

  [SyntheticMutations.REMOVE_QUEUE_ITEM](state, item) {
    state.queues.splice(state.queues.indexOf(item), 1)
  },

  [SyntheticMutations.SET_GENERATING_SYNTHETIC](state, value) {
    state.generating = value
  },

  [SyntheticMutations.SET_GENERATING_UPSCALE](state, value) {
    state.generatingUpscale = value
  },

  [SyntheticMutations.SET_REMAINING_BALANCE](state, balance) {
    state.remainingBalance = balance
  }
}
