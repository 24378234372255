export default {
  CREATE_SYNTHETIC_MEDIA: 'createSyntheticMedia',
  GET_SYNTHETIC_MEDIA: 'getSyntheticMedia',
  SAVE_SYNTHETIC_MEDIA: 'saveSyntheticMedia',
  GENERATE_TXT_TO_IMG: 'generateTxtToImg',
  GET_3D_LIBRARY_LIST: 'get3dLibraryList',
  GET_SYNTHETIC_MEDIA_HISTORICAL: 'getSyntheticMediaHistorical',
  GENERATE_UPSCALE: 'generateUpscale',
  PROCESS_QUEUE_MESSAGE: 'processQueueMessage'
}
