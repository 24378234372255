export default {
  filters: [
    {title: 'None', value: 'none'},
    {title: 'Advertising', value: 'Advertising'},
    {title: 'Artistic', value: 'Artistic'},
    {title: 'Illustration', value: 'Illustration'},
    {title: 'Realistic', value: 'Realistic'}
  ],

  maxNumberOfImages: 4,

  aspectsRatio: [
    {name: 'wide', width: 87, height: 49, widthValue: 768, heightValue: 512},
    {name: 'square', width: 58, height: 58, widthValue: 768, heightValue: 768},
    {name: 'tall', width: 50, height: 74, widthValue: 512, heightValue: 768}
  ],

  denoisingMethods: [
    {title: 'Linear', value: 'Euler a'},
    {title: 'Polynomial', value: 'LMS'},
    {title: 'Stochastic', value: 'DPM++ SDE Karras'},
  ],

  currentGenerationMessage: "",

  queues: [],

  generating: false,

  generatingUpscale: false,

  remainingBalance: 0
}
