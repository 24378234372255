import AppMutations from './mutations-types'

export default {
  [AppMutations.SET_APP_MESSAGE_TYPE](state, type) {
    state.message.type = type
  },

  [AppMutations.SET_APP_MESSAGE_TEXT](state, text) {
    state.message.text = text
  },

  [AppMutations.SET_APP_MESSAGE_OPEN](state, open) {
    state.message.open = open
  },

  [AppMutations.SET_USER_MENU_ITEMS](state, appInstances) {
    state.menuItems.forEach(item => {
      item.children = []
    })

    appInstances.forEach(instance => {
      let menuItem = state.menuItems.find(mi => mi.key == instance.application_key)

      menuItem?.children.push(instance)
    })
  },

  [AppMutations.SET_USER_APPLICATIONS](state, appInstances) {
    state.userApplications = [...appInstances]
  },

  [AppMutations.SET_SELECTED_CONTENT_APP](state, appInstanceId) {
    state.selectedContentApp = appInstanceId
  },

  [AppMutations.SET_SELECTED_CDP_APP](state, appInstanceId) {
    state.selectedCdpApp = appInstanceId
  },

  [AppMutations.UPDATE_CONTENT_PERSONAL_PREFERENCE](state, {key, value}) {
    state.contentPersonalPreferences = {...state.contentPersonalPreferences, [key]: value}

    localStorage.setItem(key, value)
  },

  [AppMutations.SET_SOCKET](state, socket) {
    state.socket = socket
  }
}