<template>
  <v-menu
    v-model="openMenu"
    offset-y
    left
    :close-on-content-click="false"
    max-width="350"
    max-height="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-progress-circular
        v-bind="attrs"
        v-on="on"
        :indeterminate="Boolean(totalProgress != null && totalProgress < 100)"
        :value="formattedGroupedActionList.length > 0 ? 100 : 0"
        color="primary"
      ></v-progress-circular>
    </template>

    <v-card
      v-if="formattedGroupedActionList.length > 0"
      class="bg-actions"
    >
      <v-expansion-panels hover>
        <v-expansion-panel
          v-for="(group, i) in formattedGroupedActionList"
          :key="i"
          :readonly="group.length === 1"
          active-class="rotate-icon"
        >
          <v-expansion-panel-header
            :hide-actions="group.length === 1"
          >
            <BackgroundActionItem :data="group[0]"/>
          </v-expansion-panel-header>

          <template v-if="group.length > 1">
            <v-expansion-panel-content
              v-for="(item, j) in group.slice(1)" :key="i+j"
            >
              <BackgroundActionItem :data="item"/>
            </v-expansion-panel-content>
          </template>
          </v-expansion-panel>
      </v-expansion-panels>

      <v-col class="pa-2 text-right">
        <v-btn
          text
          small
          color="primary"
          @click.stop="clearActionList"
        >
          {{ $t('mark_read') }}
        </v-btn>
      </v-col>
    </v-card>

    <v-card 
      v-else
      width="350"
      height="150"
    >
      <v-card-text align="center">
        <v-icon
          color="defaultIconColor"
          class="mt-4"
          large
        >mdi-check-decagram</v-icon>
      </v-card-text>

      <v-card-subtitle
        align="center"
      >{{ $t('no_background_actions') }}</v-card-subtitle>
    </v-card>

  </v-menu>
</template>

<style>
  .bg-actions .rotate-icon i.mdi-chevron-down{
    transform: rotate(180g) !important;
  }

  .bg-actions .v-expansion-panel-header,
  .bg-actions .v-expansion-panel-content__wrap {
    padding: 2px;
    padding-right: 8px;
  }

  .bg-actions .v-expansion-panel-header__icon {
    padding-right: 12px;
  }

  .bg-actions .v-expansion-panel-content:hover {
    background-color: rgba(0,0,0,0.04);
  }
</style>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'

import BackgroundActionItem from '@/components/app/BackgroundActionItem.vue'

import BackgroundActions from '@/store/background_actions/actions-types.js'

export default {
  name: 'BackgroundActionList',

  data: () => ({
    openMenu: false
  }),

  components: {
    BackgroundActionItem,
  },

  created() {
    window.addEventListener('beforeunload', this.handler)
  },

  computed: {
    ...mapState({
      actionList: state => state.background_actions.list,
    }),

    ...mapGetters("background_actions", {
      groupedActionList: "getGroupedActions"
    }),

    formattedGroupedActionList() {
      return this.groupedActionList.map(group => {

        if(group.length === 1) {
          return(group)
        }

        let actionHeader = this.getActionItemHeader(group);

        return [actionHeader, ...group]
      });
    },

    totalProgress() {
      const inProgress = this.actionList.filter(i => i.status == 'in_progress')

      const totalItems = inProgress.length

      if (totalItems <= 0) {
        return null
      }

      let totalProgress = inProgress.reduce((sum, { progress }) => {
        return sum + Number(progress);
      }, 0)

      return totalProgress / totalItems
    },
  },

  watch: {
    formattedGroupedActionList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.openMenu = true;
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('background_actions', [
      BackgroundActions.CLEAR_BACKGROUND_ACTION_LIST,
    ]),

    getActionItemHeader(group) {
      const itemHeader = {...group[0]};
      itemHeader.title += ` ${this.$t('more_others', {qty: group.length - 1})}`
      itemHeader.text = null
      itemHeader.cancelSource = null
      
      if(group.some(action => action.status === 'in_progress')) {
        itemHeader.status = 'in_progress'
      }
      
      if(group.some(action => action.status === 'error')) {
        itemHeader.status = 'partial_error'
      }
      
      if(group.every(action => action.status === 'error')) {
        itemHeader.status = 'error'
      }

      if(group.every(action => action.status === 'success' || action.status === 'done')) {
        itemHeader.status = 'success'
      }

      return itemHeader
    },

    handler() {
      if (this.actionList.some(i => i.status == 'in_progress')) {
        alert(this.$t('alert_background_actions_in_progress'))
      }
    },

    clearActionList() {
      this[BackgroundActions.CLEAR_BACKGROUND_ACTION_LIST]()
    }
  }
}

</script>