import api from '@/services/api'
import SyntheticActions from './actions-types'
import BackgroundMutations from '@/store/background_actions/mutations-types.js'
import SyntheticMutations from '@/store/content/synthetic/mutations-types.js'

export default {
  [SyntheticActions.CREATE_SYNTHETIC_MEDIA]({}, {nodeId, name}) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .create(nodeId, name)
        .then((response) => {
          resolve(response.data.id)
        })
        .catch((error) => reject(error))
    })
  },

  [SyntheticActions.GET_SYNTHETIC_MEDIA]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .get(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SyntheticActions.SAVE_SYNTHETIC_MEDIA]({}, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      api.content.actions.synthetic
        .save(nodeId, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SyntheticActions.GENERATE_TXT_TO_IMG]({ commit }, {nodeId, params}) {
    commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_SYNTHETIC]}`, true, { root:true })

    api.content.synthetic
      .txtToImg(nodeId, params)
      .then((response) => {
        const bgActionId = Math.random().toString(36).substr(2, 9)
        const bgAction = {
          bgActionId,
          title: params.file_name,
          type: 'synthetic_image_txt2img',
          createdAt: new Date(),
          progress: 0,
          status: 'in_progress',
          cancelSource: null,
        }

        commit(`background_actions/${[BackgroundMutations.REGISTER_BACKGROUND_ACTION]}`, bgAction, { root:true })

        const taskOffset = response.data.offset
        const taskTopic = response.data.queue
        const queueItem = {
          topic: taskTopic,
          offset: taskOffset,
          bgActionId: bgActionId,
          params: params,
          type: 'synthetic_image_txt2img'
        }

        commit(`${[SyntheticMutations.ADD_QUEUE_ITEM]}`, queueItem)

        api.core.key_value_store
          .getKeyValue(`${taskTopic}_current`)
          .then((response) => {
            const data = JSON.parse(response.data?.value) || {}

            const currentOffset = data?.offset || 0
            const queueOffset = taskOffset - currentOffset
            let generationMessage = `In Queue: ${queueOffset}`

            if (queueOffset <= 0) {
              generationMessage = 'generating'
            }

            commit(`content/synthetic/${[SyntheticMutations.SET_CURRENT_GENERATION_MESSAGE]}`, generationMessage, { root:true })
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, { bgActionId, status: generationMessage }, { root:true })
        })
        .catch(() => {
          commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_SYNTHETIC]}`, false, { root:true })
          commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, { bgActionId, status: 'error' }, { root:true })
        })
    })
    .catch(() => {
      commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_SYNTHETIC]}`, false, { root:true })
    })
  },

  [SyntheticActions.GET_3D_LIBRARY_LIST]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.actions.synthetic
        .get3dLibraryList(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
  },

  [SyntheticActions.GET_SYNTHETIC_MEDIA_HISTORICAL]({}, {nodeId, options}) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .historical(nodeId, options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
  },

  [SyntheticActions.GENERATE_UPSCALE]({ commit }, {nodeId, upscale, file_name}) {
    commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_UPSCALE]}`, true, { root:true })

    api.content.synthetic
      .upscale(nodeId, upscale)
      .then((response) => {
        const bgActionId = Math.random().toString(36).substr(2, 9)
        const bgAction = {
          bgActionId,
          title: file_name,
          type: 'synthetic_image_upscale',
          createdAt: new Date(),
          progress: 0,
          status: 'in_progress',
          cancelSource: null,
        }

        commit(`background_actions/${[BackgroundMutations.REGISTER_BACKGROUND_ACTION]}`, bgAction, { root:true })

        const taskOffset = response.data.offset
        const taskTopic = response.data.queue

        const queue = {topic: taskTopic, offset: taskOffset, bgActionId: bgActionId, upscale: upscale, type: 'synthetic_image_upscale'}

        commit(`${[SyntheticMutations.ADD_QUEUE_ITEM]}`, queue)
      })
      .catch(() => {
        commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_UPSCALE]}`, false, { root:true })
      })
  },

  [SyntheticActions.PROCESS_QUEUE_MESSAGE]({ state, commit }, response) {
    const title = response.title

    if (/^synthetic_image_[0-9]+_current$/.test(title)) {
      if (!state.generating) {
        return
      }

      const data = JSON.parse(response?.text) || {}

      state.queues.forEach(queue => {
        if (queue.topic + '_current' == title) {
          let queueOffset = queue.offset - data.offset
          let message = `In Queue: ${queueOffset}`
          let bgActionId = queue.bgActionId

          if (queueOffset <= 0) {
            message = 'generating'
          }

          commit(
            `content/synthetic/${[SyntheticMutations.SET_CURRENT_GENERATION_MESSAGE]}`,
            message,
            { root:true }
          )

          commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, { bgActionId, status: 'in_progress' }, { root:true })
        }
      })

      return
    }
 
    const match = title.match(/^(synthetic_image_[0-9]+)_([0-9]+)_status$/)

    if (!match) {
      return
    }

    const data = JSON.parse(response?.text) || {}
    const taskTopic = match[1]
    const taskOffset = parseInt(match[2])

    state.queues.forEach(queue => {
      if (queue.topic != taskTopic || queue.offset != taskOffset) {
        return
      }

      commit(
        `content/synthetic/${[SyntheticMutations.UPDATE_QUEUE_ITEM]}`,
        {topic: queue.topic, offset: queue.offset, status: data.status},
        { root:true }
      )

      commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_SYNTHETIC]}`, false, { root:true })

      let bgActionId = queue.bgActionId

      if (data.status == 'success') {
        commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, { bgActionId, status: data.status }, { root:true })

        if (queue.type == 'synthetic_image_upscale') {
          let remainingBalance = state.remainingBalance - queue.upscale.cost
          commit(
            `content/synthetic/${[SyntheticMutations.SET_REMAINING_BALANCE]}`,
            remainingBalance,
            { root:true }
          )
        }
      }
      else {
        commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, { bgActionId, status: data.status }, { root:true })
      }

      if (queue.type == 'synthetic_image_upscale') {
        commit(`content/synthetic/${[SyntheticMutations.SET_GENERATING_UPSCALE]}`, false, { root:true })
      }
    })
  }
}
