import AxiosFileService from '@/services/api/fileservice/axios_fileservice'

export default {
  uploadToFileService: ({nodeId, fileName, file, fileServiceUrl, parentExternal, callback}, source, onUploadProgress) => {
    let formData = new FormData()
    formData.append("parent_id", parentExternal)
    formData.append("node_id", nodeId)
    formData.append("file_name", fileName)
    formData.append("callback", callback)
    formData.append("raw_data", file)

    return AxiosFileService.post("/file/upload", formData, {
      baseURL: fileServiceUrl,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      cancelToken: source.token,
      onUploadProgress
    })
  }
}
